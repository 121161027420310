import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
	DatasetsDTO,
	FtBox,
	Hl7IdName,
	LabelDTO,
	PathologyDTO,
	PhysicianDTO,
	PriorityDTO,
	ProcedureCodeDTO,
	QueryParam,
	ReasonForExamDTO,
	RoomDTO,
	SmsTemplateDTO,
	StaffContractDTO,
	Technique,
	TemplateModelDTO,
} from '../model';
import {
	EXAMS_URL,
	PACS_URL,
	PATIENT_URL,
	PHARMACY_URL,
	PRINTER_URL,
	QUEUE_URL,
	RESOURCE_URL,
	SCHEDULER_URL,
	SETTING_URL,
	SHARED_URL,
	STATIC_URL,
	WORKFLOW_URL,
} from '../urls';
import { SortDirection } from '@angular/material/sort';
import { Prescription } from './prescription';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { printBlob } from '../utils';

@Injectable()
export class SharedService {
	pacsSettingUrl = `/api/setting`;

	private _templateChangedSource = new Subject<any>();
	private _http = inject(HttpClient);

	changeTemplate = (tm: any) => this._templateChangedSource.next(tm);

	getPrinters(): Observable<any> {
		return this._http.get(PRINTER_URL + '/printers');
	}

	queryPacs(queryParam: QueryParam): Observable<QueryParam[]> {
		if (queryParam.validQuery())
			return this._http.post<QueryParam[]>(
				`${PACS_URL}/query`,
				queryParam,
			);
		return of([]);
	}

	printAttestation(id: number): Observable<any> {
		return this._http
			.get(`${PRINTER_URL}/printAttestation`, {
				params: { id },
				responseType: 'blob',
			})
			.pipe(tap(printBlob));
	}

	getLicenseInfo(): Observable<any> {
		return this._http.get('/api/documents/infos');
	}

	getPathologies = (category: string): Observable<PathologyDTO[]> =>
		this._http.get<PathologyDTO[]>(STATIC_URL + '/pathology-list', {
			params: { category },
		});

	getPaginatedTechniques(
		pageSize: number,
		pageIndex: number,
		sort: string,
		direction: string,
		value,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort,
			direction,
			value,
		};
		return this._http.get<any>(`${RESOURCE_URL}/techniques`, {
			params: params,
		});
	}

	createTechnique(technique: any): Observable<Technique> {
		return this._http.post<Technique>(
			`${RESOURCE_URL}/saveTechnique`,
			technique,
		);
	}

	deleteTechnique(techniqueId: any): Observable<boolean> {
		return this._http.get<boolean>(`${RESOURCE_URL}/deleteTechnique`, {
			params: { techniqueId },
		});
	}

	getRooms = (): Observable<RoomDTO[]> =>
		this._http.get<RoomDTO[]>(RESOURCE_URL + '/rooms');

	deleteRoom(roomId: number): Observable<boolean> {
		return this._http.delete<boolean>(RESOURCE_URL + `/rooms/${roomId}`);
	}

	createRoom(room: RoomDTO): Observable<RoomDTO> {
		return this._http.post<RoomDTO>(RESOURCE_URL + '/rooms', room);
	}

	getModalityAETitles = (modalityId: number): Observable<any> =>
		this._http.get(STATIC_URL + `/modalityAETs/${modalityId}`);

	getPerformingPhysicians = (): Observable<any> =>
		this._http.get(STATIC_URL + '/performingPhysicians');

	getFunctions = (): Observable<any> =>
		this._http.get(STATIC_URL + '/functions');

	getImagingCenters = (): Observable<any> =>
		this._http.get(SETTING_URL + '/imaging-centers');

	getUsers = (): Observable<any> => this._http.get('/api/setting/users');

	getProfiles = (): Observable<any> =>
		this._http.get('/api/setting/profiles');

	getModalities = (): Observable<any> =>
		this._http.get(STATIC_URL + '/modalities');

	getBillingCodes = (): Observable<any> =>
		this._http.get(STATIC_URL + '/billingCodes');

	getProcedureCodes = (): Observable<any> =>
		this._http.get(RESOURCE_URL + '/procedureCodes');

	getPriorities = (): Observable<any> =>
		this._http.get(STATIC_URL + '/priorities');

	getGenders = (): Observable<any> => this._http.get(STATIC_URL + '/genders');

	getMaritalStatuses = (): Observable<any> =>
		this._http.get(STATIC_URL + '/maritalStatuses');

	getTitles = (): Observable<any> => this._http.get(STATIC_URL + '/titles');

	getAetList = (): Observable<any> => this._http.get(RESOURCE_URL + '/aets');

	getReasonForExams = (): Observable<ReasonForExamDTO[]> =>
		this._http.get<ReasonForExamDTO[]>(STATIC_URL + '/reason-for-exams');
	getReasonForExamsValues = (): Observable<any> =>
		this._http.get(STATIC_URL + '/reason-for-exams/values');

	getTechnicians = (): Observable<any> =>
		this._http.get(STATIC_URL + '/technicians');

	savePathology = (pathology: PathologyDTO): Observable<PathologyDTO> =>
		this._http.post<PathologyDTO>(STATIC_URL + '/pathologies', pathology);

	generateAccessionNumber = (): Observable<any> =>
		this._http.get(`${SHARED_URL}/new-order-id`);

	generatePatientId = (): Observable<any> =>
		this._http.get(`${SHARED_URL}/new-patient-id`);

	getViewers = (): Observable<any> =>
		this._http.get<any>(`${this.pacsSettingUrl}/viewers`);

	getRadiologists = (): Observable<any> =>
		this._http.get(`${STATIC_URL}/performingPhysicians`);

	getAllergies = (): Observable<any> =>
		this._http.get(`${STATIC_URL}/allergies`);

	getSpecialities = (): Observable<any> =>
		this._http.get(`${STATIC_URL}/specialities`);

	getPatientClasses = (): Observable<any> =>
		this._http.get(`${STATIC_URL}/patientClasses`);

	getConfidentialities = (): Observable<any> =>
		this._http.get(`${STATIC_URL}/confidentialities`);

	deleteReferringPhysician = (item: any): Observable<any> =>
		this._http.delete(`${SHARED_URL}/referring-physicians/${item.id}`);

	generatePrescription = (prescription: Prescription): Observable<boolean> =>
		this._http.post<boolean>(
			`${SHARED_URL}/generatePrescription`,
			prescription,
		);

	getPaymentMethods = (): Observable<any> =>
		this._http.get(`${RESOURCE_URL}/paymentMethods`);

	createBank = (bank: any): Observable<any> =>
		this._http.post(`${RESOURCE_URL}/createBank`, bank);

	createAllergy = (allergy: any): Observable<any> =>
		this._http.post(`${STATIC_URL}/createAllergy`, allergy);

	getPaginatedProcedureCodes(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		value: string,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			value,
		};
		return this._http.get(`${RESOURCE_URL}/procedures`, { params });
	}

	queryProcedureCodes(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		value: string,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			value,
		};
		return this._http.get(`${SHARED_URL}/procedure-codes`, { params });
	}

	printPatientsPerDate(date: string): Observable<any> {
		return this._http
			.get(`${PRINTER_URL}/printPatientPerDate`, {
				responseType: 'blob',
				params: { date },
			})
			.pipe(tap(printBlob));
	}

	getPaginatedPostalCodes(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		key: string,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			key,
		};
		return this._http.get(`${STATIC_URL}/postalCodes`, { params });
	}

	getPaginatedBillingCodes(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		key: string,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			key,
		};
		return this._http.get(`${RESOURCE_URL}/billing-codes`, { params });
	}

	getStaffContracts(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		key: string,
	) {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			key,
		};
		return this._http.get(`${RESOURCE_URL}/staff-contracts`, { params });
	}

	createStaffContract(
		staffContract: StaffContractDTO,
	): Observable<StaffContractDTO> {
		return this._http.post<StaffContractDTO>(
			`${RESOURCE_URL}/staff-contracts`,
			staffContract,
		);
	}

	deleteStaffContract(id: any): Observable<boolean> {
		return this._http.delete<boolean>(
			`${RESOURCE_URL}/staff-contracts/${id}`,
		);
	}

	getStaffContract(id: number): Observable<StaffContractDTO> {
		return this._http.get<StaffContractDTO>(
			`${RESOURCE_URL}/staff-contracts/${id}`,
		);
	}

	getAllStaffContracts(): Observable<StaffContractDTO[]> {
		return this._http.get<StaffContractDTO[]>(
			`${RESOURCE_URL}/staff-contracts-list`,
		);
	}

	getOrganismsList(): Observable<Hl7IdName[]> {
		return this._http.get<Hl7IdName[]>(`${RESOURCE_URL}/organismsList`);
	}

	getOrganismConventions(organismId: any): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/organismConventions`, {
			params: { organismId },
		});
	}

	getOrganismPECS(patientID: string, organismId: any): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/patientPECS`, {
			params: { patientID: patientID, organismId: organismId },
		});
	}

	getConventionException(conventionId: any): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/conventionExceptions`, {
			params: { conventionId },
		});
	}

	mergeExams(row: QueryParam, accessionNumber: string): Observable<any> {
		const params = { accessionNumber };
		return this._http.post(`${EXAMS_URL}/merge`, row, { params });
	}

	checkIfExamExists(studyUID: string): Observable<boolean> {
		return this._http.get<boolean>(`${SCHEDULER_URL}/checkExistsByUID`, {
			params: { studyUID },
		});
	}

	getTariffs(): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/tariffs`);
	}

	getTariffLines(id: any): Observable<any> {
		const params = { id };
		return this._http.get(`${RESOURCE_URL}/tariffLines`, { params });
	}

	getUsersWithPaymentRight(): Observable<any> {
		return this._http.get(`${SETTING_URL}/usersWithPaymentRight`);
	}

	getBoxes(): Observable<FtBox[]> {
		return this._http.get<FtBox[]>(`${SETTING_URL}/boxes`);
	}

	saveBox(box: any): Observable<any> {
		return this._http.post(`${SETTING_URL}/saveBox`, box);
	}

	getStoreList(): Observable<any> {
		return this._http.get(`${PHARMACY_URL}/stores-list`);
	}

	getArticles(storeId: any): Observable<any> {
		return this._http.get(`${PHARMACY_URL}/articles-list`, {
			params: { storeId },
		});
	}

	getArticleSerials(articleId: any, storeId: any): Observable<any> {
		return this._http.get(`${PHARMACY_URL}/article-serials`, {
			params: { articleId, storeId },
		});
	}

	getArticleLots(articleId: any, storeId: any): Observable<any> {
		return this._http.get(`${PHARMACY_URL}/article-lots`, {
			params: { articleId, storeId },
		});
	}

	createTitle(title: any): Observable<any> {
		return this._http.post(`${STATIC_URL}/createTitle`, title);
	}

	getExamTypes(): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/examTypes`);
	}

	getQueueItems(): Observable<any> {
		return this._http.get(`${QUEUE_URL}`);
	}

	getProcedureCodeById(id: any): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/procedureCodeById`, {
			params: { id },
		});
	}

	findProcedureCodeDTO(
		studyDescription: string,
		modalitiesInStudy: any,
	): Observable<ProcedureCodeDTO> {
		return this._http.get<ProcedureCodeDTO>(
			`${SHARED_URL}/procedures/find`,
			{
				params: {
					studyDescription,
					modalitiesInStudy,
				},
			},
		);
	}

	loadLogs(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		key,
		user?: any,
		startDate?: any,
		endDate?: any,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${active},${direction}`,
			key,
			user,
			startDate,
			endDate,
		};
		return this._http.get(`${SHARED_URL}/logging`, { params });
	}

	exportPhysicians(): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/exportPhysicians`, {
			responseType: 'blob',
		});
	}

	exportProcedures(): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/exportProcedures`, {
			responseType: 'blob',
		});
	}

	sendSms(
		id: number,
		destination: any,
		message: any,
		source: string = null,
	): Observable<any> {
		return this._http.get(`${WORKFLOW_URL}/sendSMS`, {
			params: { id, destination, message, source },
		});
	}

	getSmsTemplates(): Observable<SmsTemplateDTO[]> {
		return this._http.get<SmsTemplateDTO[]>(
			`${RESOURCE_URL}/sms-templates`,
		);
	}

	saveSmsTemplate(template: any): Observable<SmsTemplateDTO> {
		return this._http.post<SmsTemplateDTO>(
			`${RESOURCE_URL}/sms-templates`,
			template,
		);
	}

	deleteSmsTemplate(id: number): Observable<boolean> {
		return this._http.delete<boolean>(
			`${RESOURCE_URL}/sms-templates/${id}`,
		);
	}

	getProcedureCodesValues(): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/findProcedureCodesValues`);
	}

	isReferringPhysicianUsed(id: any): Observable<any> {
		return this._http.get(`${PATIENT_URL}/physician-used`, {
			params: { id },
		});
	}

	getPrintHistory(targetDocuments: any, documentId: string): Observable<any> {
		const query = [targetDocuments.join('-'), documentId].join('@');
		return this._http.get(`${PRINTER_URL}/print-history`, {
			params: { query },
		});
	}

	mergePhysicians(res: any, physicianIds: string): Observable<any> {
		return this._http.post(`${SHARED_URL}/mergePhysicians`, res, {
			params: { physicianIds },
		});
	}

	getLabels(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		filter,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			filter,
		};
		return this._http.get(`${SETTING_URL}/labels`, { params });
	}

	deleteLabel(labelId: number): Observable<any> {
		return this._http.delete(`${SETTING_URL}/labels/${labelId}`);
	}

	saveLabel(label: LabelDTO): Observable<LabelDTO> {
		return this._http.post<LabelDTO>(`${SETTING_URL}/labels`, label);
	}

	getReferringPhysicianById(doctorId: number): Observable<PhysicianDTO> {
		return this._http.get<PhysicianDTO>(
			`${SHARED_URL}/referring-physicians/${doctorId}`,
		);
	}

	getDatasets(datasets: string): Observable<DatasetsDTO> {
		return this._http.get<DatasetsDTO>(`${SHARED_URL}/datasets`, {
			params: { datasets },
		});
	}

	queryReferringPhysicians(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		value: any,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			value,
		};
		return this._http.get(`${SHARED_URL}/referring-physicians`, { params });
	}

	getTechnique(techniqueId: number): Observable<any> {
		return this._http.get(`${SHARED_URL}/technique/${techniqueId}`);
	}

	getEditorUsers(): Observable<any> {
		return this._http.get(`${SHARED_URL}/editor-users`);
	}

	getProcedureCodeDTO(id: number): Observable<ProcedureCodeDTO> {
		return this._http.get<ProcedureCodeDTO>(
			`${SHARED_URL}/procedure-codes/${id}`,
		);
	}

	getTemplateModelById = (
		templateModelId: number,
	): Observable<TemplateModelDTO> => {
		return this._http.get<TemplateModelDTO>(
			`${SHARED_URL}/template-models/${templateModelId}`,
		);
	};

	saveReasonForExam(
		reasonForExam: ReasonForExamDTO,
	): Observable<ReasonForExamDTO> {
		return this._http.post<ReasonForExamDTO>(
			`${STATIC_URL}/reason-for-exams`,
			reasonForExam,
		);
	}

	checkProcedureCodeExistence(code: string): Observable<boolean> {
		return this._http.get<boolean>(`${SHARED_URL}/procedure-code-exists`, {
			params: { code },
		});
	}

	createPriority(value: PriorityDTO): Observable<PriorityDTO> {
		return this._http.post<PriorityDTO>(`${STATIC_URL}/priorities`, value);
	}

	deletePriority(id: any): Observable<boolean> {
		return this._http.delete<boolean>(`${STATIC_URL}/priorities/${id}`);
	}

	getPathologyCategories(): Observable<any> {
		return this._http.get(`${STATIC_URL}/pathology-categories`);
	}

	updatePathologyCategory(
		currentCategory: string,
		category: any,
	): Observable<boolean> {
		return this._http.put<boolean>(
			`${STATIC_URL}/pathology-categories`,
			null,
			{ params: { currentCategory, category } },
		);
	}

	getAllUsers(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		query: any,
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			query,
		};
		return this._http.get(`${SETTING_URL}/all-users`, { params });
	}

	getShortProfiles(): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/profiles`);
	}

	getPECLines(pecId: any): Observable<any> {
		return this._http.get(`${RESOURCE_URL}/pecExams`, {
			params: { pecId },
		});
	}

	getProcedureCodeDTOByCode(code: string): Observable<ProcedureCodeDTO> {
		return this._http.get<ProcedureCodeDTO>(
			`${SHARED_URL}/procedure-code`,
			{ params: { code } },
		);
	}
}
